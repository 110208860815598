import React from "react"
import img from "../images/services.jpg"
import Back from "../common/Back"
import "../home/featured/Featured.css"
import FeaturedCard from "../home/featured/FeaturedCard"
import img1 from "../images/image/Dhanishtha Mukhiya.jpg";
import {
  // Button,
  Typography,
} from "@material-tailwind/react";

const Services = () => {
  return (
    <>
      <section className='services mb'>
        <Back name='Services' title='Services -All Services' cover={img} />
        <div className='featured container'>
          <FeaturedCard />
        </div>
      </section>
      <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container grid place-items-center grid-cols-1 md:grid-cols-2" style={{ gridTemplateColumns: '30% 70%' }}>
          <img
            src={img1}
            alt="pink blazer"
            className="h-[32rem] w-[32rem] overflow-hidden object-cover"
          />
          <div>
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              Dhanishtha Mukhiya
            </Typography>
            <Typography variant="h5" className=" text-gray-600">
              Managing Partner
            </Typography>

            <Typography className="!mt-4 text-base  leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Dhanishtha Mukhiya is the managing partner of the firm. She is a
              practicing advocate since July, 2017 and is registered member of
              Bar Council of Rajasthan. Dhanishtha has appeared before Hon’ble
              Supreme Court of India, Hon’ble High Court of Rajasthan, Jaipur
              and Jodhpur Bench, Learned District and Session Courts of New
              Delhi and Jaipur, High Court of Delhi, Debt Recovery Tribunal,
              Jaipur, District Consumer Forum, Jaipur, State Consumer
              Commission, Waqf Tribunal, National Company Law Tribunal, Jaipur,
              Family Court, Jaipur and various other forums and authorities. She
              has been litigating since the start of her legal career.
            </Typography>
            <Typography className="!mt-4 text-base   leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Dhanishtha graduated with first division from Amity Law School,
              Amity University, Uttar Pradesh with a five-year degree in
              bachelor of arts and bachelor of law along with honors in Business
              Laws. Dhanishtha has dedicatedly completed her internships under
              fire brand lawyers like Senior Advocate Salman Khurshid, Senior
              Advocate P.S. Narsimha in Supreme Court of India. She also learned
              the basics of trial by interning in various trial courts of
              Udaipur, Rajasthan and New Delhi.
            </Typography>
            <Typography className="!mt-4 text-base   leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Dhanishtha has worked under prominent lawyers of Jaipur City like
              Senior Advocate Sudhanshu Kasliwal, Advocate Suruchi Kasliwal and
              Advocate Ram Naresh Vijay. During her time in these offices, she
              handled cases for esteemed organizations like Indian Oil
              Corporation Limited, State Bank of India, East India Hotels, Oil
              and Natural Gas Corporation Limited, Bank of Baroda, ICICI, AU
              Small Finance Bank, Union Bank of India etc.
            </Typography>
            {/* <div className="mb-4 flex w-full items-center gap-3 md:w-1/2 ">
            <Button color="gray" className="w-52">
              Add to Cart
            </Button>
          </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Services
