import { Typography} from "@material-tailwind/react"; //In case you want to use Card, you will have to add card in front of Typography separated with a comma//

import Back from "../common/Back";
// import Heading from "../common/Heading";
// import img from "../images/about.jpg"
// import { Button } from "@material-tailwind/react"
import img from "../images/image/7.1.jpg";

export function Civil() {

  return (
    <>
      <section className='text-4xl mb'>
        <Back name='Practice Areas' title='' cover={img} />
      </section>
      <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container">
          <div>
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              Civil Litigation
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Litigation is a complex and time taking process in India demanding patience
              and robust strategic approach. Legal Inkwell is handling litigation matters 
              throughout India including the Supreme Court of India and various High Courts.
              Our Advocates are members of Supreme Court Bar Association, High Court Bar Association
              as well as other Bar Associations. Our Firm is adept in effectively handling following
              categories of cases:
            </Typography>
            <ul className="list-disc ml-12 mt-4" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Suits for Recovery of Money;</li>
              <li>Suits for Damages;</li>
              <li>Mandatory and Permanent Injunctions;</li>
              <li>Declaration Suits;</li>
              <li>Commercial and Contractual Disputes;</li>
              <li>Eviction Suits;</li>
              <li>Property Suits;</li>
              <li>Employee / Employer Claims;</li>
              <li>Consumer Law;</li>
              <li>Employment Law;</li>
              <li>Product Liability;</li>
              <li>Medical Negligence;</li>
              <li>Environmental Litigation;</li>
              <li>Construction Disputes;</li>
              <li>Family & Matrimonial Matters;</li>
              <li>Probate, Successions and Trusts;</li>
              <li>Dispute Resolution in various judicial and quasi-judicial tribunals / forums including at National Company Law Tribunal, Appellate Tribunals, Designated Authorities and other adjudicatory bodies.</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Civil;