
import React from "react";
import Back from "../common/Back";
import img from "../images/image/7.1.jpg";
import { Typography } from "@material-tailwind/react";
const Corporate = () => {

  return (

    <div>
      <section className="text-4xl mb">
        <Back name="Practice Areas" title="" cover={img} />
        </section>
        <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container">
          <div>
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              Corporate Laws
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Legal Inkwell has an excellent Corporate Practice. Our Firm combines its expertise over
              relevant corporate and commercial laws along with extensive understanding of Clients' business
              and industry. This extensive research based approach enables our Firm to provide practical and 
              innovative solutions which are also harmonious with the business world.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              We provide services in:
            </Typography>
            <ul className="list-disc ml-6" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Capital Markets;</li>
              <li>Competition Law / Anti Trust Law;</li>
              <li>White Collar Crimes & Investigations;</li>
              <li>Mergers & Acquisitions (Legal, Statutory & Regulatory);</li>
              <li>Finance;</li>
              <ul className="list-disc ml-14" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Compliance;</li>
              <li>Advisory;</li>
              <li>Setting up of Companies / Operations in India;</li>
              <li>Entry Level Strategies;</li>
              <li>New Financial Product Offerings;</li>
              <li>Development of New Products;</li>
              <li>Government & Regulatory Authorities;</li>
              <li>Overseas Direct Investment;</li>
              <li>Foreign Direct Investment;</li>
              </ul>
              <li>Joint Ventures;</li>
              <li>Legal Due Diligence;</li>
              <li>Start-up Companies</li>
            </ul>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              We also take pride on our drafting of various large, exhaustive and complex
              contracts which includes:
            </Typography>
            <ul className="list-disc ml-6" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>EPC Contracts and Construction Contracts;</li>
              <li>Project Management Contracts;</li>
              <li>Offshore and Onshore Supplies and Services Contracts;</li>
              <li>Asset Purchase Agreements;</li>
              <li>Business Transfer Agreements;</li>
              <li>Share Purchase / Share Subscription Agreements;</li>
              <li>Lease Agreements for large commercial properties;</li>
              <li>Conveyance and Mortgage Documents;</li>
              <li>Sale Agreements / Sale Deeds;</li>
              <li>Distributorship Agreements;</li>
              <li>Franchise Agreements;</li>
              <li>Technology Agreements;</li>
              <li>Software Agreements;</li>
              <li>Labour and Employments Agreements;</li>
              <li>Non - Disclosure Agreements.</li>
              </ul>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Corporate;