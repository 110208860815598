import React from "react";
import "./hero.css";
import img from "../../images/image/10.png";

const Hero = () => {
  return (
    <div className="relative overflow-hidden">
      <img src={img} alt="image 1" className="h-full w-full object-cover" />
    </div>
  );
};

export default Hero;
