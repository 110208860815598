import React, { useEffect }from "react";
import img from "../../images/image/7.1.jpg";
import Back from "../../common/Back";
import img1 from "../NFT - Non Fungibel Token/Picture1.jpg";
import { Typography } from "@material-tailwind/react";

const NFT = () => {
  useEffect(() => {
    window.scrollTo(0, 380);
  }, []);

  return (
    <div>
      <section className=' text-4xl mb '>
        <Back name='Articles' title='' cover={img} />
      </section>
      <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container items-center">
          <img
            src={img1}
            alt="pink blazer"
            className="h-[362px] w-[733px] object-cover mb-8 mt-4"
            style={{objectPosition: 'center', objectFit: 'cover', marginLeft: 'auto', marginRight: 'auto'}}
          />
          <div className="md:pl-8 flex flex-col justify-center">
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              NFT's
            </Typography>
            <Typography variant="h5" className=" text-gray-600">
              Non Fungible Tokens
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Non-Fungible Tokens or NFT’s, in short, have been gaining a lot of traction in recent times with the advent of Metaverse. Non-Fungible in terms of NFTs simply means something that is not directly changeable or something that can be replaced. Every NFT that is in existence is unique and cannot be replaced with a similar unit. A completely opposite example of Non-Fungible item is a currency note, every currency note may be unique, but can be replaced with something of the same value and its uniqueness (with regard to its serial number) has nothing to do with its value.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              A NFT can be anything from an image to a video clip or any sort of digital certificate, however the emphasis is placed on its ownership, transparency and interoperability. Imagine an artist creating a work of digital art, while creating a NFT for the said digital art, he will be in a position to determine how many copies there would be for the particular art, embed his own signature or name within the metadata of the said NFT as well as provide for the ownership of the said digital art or what rights would the subsequent owner would have with regard to the said NFT.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              NFTs are based on blockchain technology, wherein no one person would hold the data for the ownership, creator’s details and uniqueness of the said NFT and the said information would be available throughout the blockchain thereby reducing the chances of forgery, theft and digital corruption. With blockchain technology, no person would be able to make changes to NFT once they are made and the data (including usage rights, number of copies in circulation, or any other condition that the creator may place, etc.) would be embedded into its metadata which would not be changeable.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Many people think that NFTs could provide a huge use case scenario given the advantages it derives from the blockchain technology. For example, a person’s Driving license can be made as an NFT wherein his ownership, his credentials and expiry would be embedded into its metadata and no one would be able to copy the same. The use case may also include the Registration details of a vehicle, proof of ownership of a physical property, a concert’s tickets, etc. and is only limited by a creator’s imagination.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Currently, NFTs are available on Ethereum blockchain and can be bought and sold using Ether. However, the technology is at its evolving stage and is facing criticism due to its digital nature. One of the major issues for the NFTs, like any other digital content, is copy and paste. For example, a person would still be able to copy an artistic painting in the digital form (Image Format and not NFT), however, this would not change the original NFT and its ownership and rights would remain intact.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              In the present world, the NFTs are gaining huge traction due to its ease of use and transparency while emphasizing on its speculative nature wherein the NFT can be sold or bought with ease, however, only time will tell if the Non-Fungible Tokens would be able to provide real world use cases or die like many other technologies before it.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <span style={{fontWeight: 'bold'}}>Disclaimer:</span> <span style={{fontSize: '1rem'}}>The contents of this publication are solely for information and general knowledge and not for advertising, marketing, soliciting or to build an advocate-client relationship. This is not professional advice or opinion which may be sought before initiation of any legal action. Although everything contained hereinabove is extensively researched, Legal inkwell does not assume any responsibility for any errors, which may inadvertently appear. Legal inkwell will also be not responsible for anyone acting/ refraining by placing reliance on contents of this publication resulting to any kind of loss or damage.</span>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Copyright © 2024
            </Typography>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NFT;
