import React from 'react'

import Back from "../common/Back";
import Heading from "../common/Heading";
import img from "../images/image/7.1.jpg"
import { Button } from "@material-tailwind/react";
const Newsltr = () => {

    return (
        <div>

            <div>
                <section className="about text-4xl">
                    <Back name="Newsletters" title="From the desk!" cover={img} />
                    <div className="container flex mtop">
                        <div className="left row text-xl shadow mb-8">
                            <Heading
                                title="NEWSLETTER 1"
                                subtitle="MAYBE MONTHLY / BY AUTHOR / BY DATE"
                            />

                            <p>
                                FIRST PARAGRAPH OF SOMETHING
                            </p>
                            <p>
                                SECOND PARAGRAPH OF ANYTHING
                            </p>
                            <p>
                                THIRD PARAGRAPH OF ANYTHING
                            </p>
                            {/* <button className="btn2">More About Us</button> */}
                            <Button variant="text" className="flex items-center gap-2">
                                Read More{" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="h-5 w-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                    />
                                </svg>
                            </Button>
                        </div>
                        {/* <div className="right row">
                            <img src={img} alt="" />
                        </div> */}
                    </div>
                    <div className="container flex mtop">
                        <div className="left row text-xl shadow mb-8">
                            <Heading
                                title="NEWSLETTER 2"
                                subtitle="BY MONTH / BY AUTHOR / BY DATE / BY CONTENT"
                            />

                            <p>
                                FIRST PARAGRAPH OF SOMETHING
                            </p>
                            <p>
                                SECOND PARAGRAPH OF ANYTHING
                            </p>
                            <p>
                                THIRD PARAGRAPH OF ANYTHING
                            </p>
                            {/* <button className="btn2">More About Us</button> */}
                            <Button variant="text" className="flex items-center gap-2">
                                Read More{" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="h-5 w-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                    />
                                </svg>
                            </Button>
                        </div>
                        {/* <div className="right row">
                            <img src={img} alt="" />
                        </div> */}
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Newsltr
