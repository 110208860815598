import React, { useEffect }from "react";
import img from "../../images/image/7.1.jpg";
import Back from "../../common/Back";
import img1 from "../Genetic Discrimination/Picture2.jpg";
import { Typography } from "@material-tailwind/react";

const Genetic = () => {
  useEffect(() => {
    window.scrollTo(0, 380);
  }, []);

  return (
    <div>
      <section className=' text-4xl mb '>
        <Back name='Articles' title='' cover={img} />
      </section>
      <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container items-center">
          <img
            src={img1}
            alt="pink blazer"
            className="h-[362px] w-[733px] object-cover mb-8 mt-4"
            style={{objectPosition: 'center', objectFit: 'cover', marginLeft: 'auto', marginRight: 'auto'}}
          />
          <div className="md:pl-8 flex flex-col justify-center">
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              Genetic Discrimination and Privacy of Genetic Data
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Medical Science has been developing at a very fast pace and consequently, we have more cures and precautions for diseases. Emergence of Covid-19 has been an explicit warning that development of Medical Sciences is crucial in every sense or we will be dealing with mass deaths in single wave of any pandemic.  
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            India is also a part of growth and development of Medical Sciences. We have latest equipment and Advanced research which helps experts to perform tests on samples collected from the patients. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Discrimination on the basis of medical condition which are present due to genetics by insurance companies has been a heated topic of discussion for quite some time now. This sort of discrimination is a clear violation of Article 14 of Constitution of India i.e. Right to Equality amongst all the citizens. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Our keen Judiciary settled one of these disputes in the matter of United India Insurance Company v. Jay Prakash Tayal which was heard by Hon’ble High Court of Delhi. It clearly states that it is unconstitutional to discriminate against people with genetic abnormalities while allowing Insurance Companies to charge premium at higher rates from such clients after proper medical testing. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            The existence of only one judgment in regard to this issue indicates that we are in dire need of more awareness and proper legislature to eradicate such discrimination at ground level which includes other facets like employment, sports, education etc. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            A simple procedure of extracting blood by a recognised and regulated Hospital or path lab can result in violation of our privacy. Blood samples and other bodily fluids and components are collected on daily basis by hospitals and path labs by experts for efficient diagnosis of patients. Our country already has laws protecting our DNA which is part of the samples collected from our bodies but no law protects misuse of our genetic material. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Hence, protection of our genetic data is very crucial in this rapidly developing world where identity thefts and frauds has become a daily hassle for common men and women. It is also a clear violation of Article 21 of the Constitution of India which enables Right to life and personal liberty. Article 21’s broad ambit covers our right to live with dignity and respect and protection of our genetic material is a big part of that life.  
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <span style={{fontWeight: 'bold'}}>Disclaimer:</span> <span style={{fontSize: '1rem'}}>The contents of this publication are solely for information and general knowledge and not for advertising, marketing, soliciting or to build an advocate-client relationship. This is not professional advice or opinion which may be sought before initiation of any legal action. Although everything contained hereinabove is extensively researched, Legal inkwell does not assume any responsibility for any errors, which may inadvertently appear. Legal inkwell will also be not responsible for anyone acting/ refraining by placing reliance on contents of this publication resulting to any kind of loss or damage.</span>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Copyright © 2024
            </Typography>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Genetic;
