import React, { useEffect } from "react";
import img from "../../images/image/7.1.jpg";
import Back from "../../common/Back";
import img1 from "../Space-Tech/Picture1.jpg";
import { Typography } from "@material-tailwind/react";

const Spacetech = () => {
  useEffect(() => {
    window.scrollTo(0, 380);
  }, []);

  return (
    <div>
      <section className='text-4xl mb'>
        <Back name='Articles' title='' cover={img} />
      </section>
      <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container items-center">
          <img
            src={img1}
            alt="pink blazer"
            className="h-[362px] w-[733px] object-cover mb-8 mt-4"
            style={{ objectPosition: 'center', objectFit: 'cover', marginLeft: 'auto', marginRight: 'auto' }}
          />
          <div className="md:pl-8 flex flex-col justify-center">
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              Need for Space-Tech Laws in India 
            </Typography>
            <Typography variant="h5" className="text-gray-600">
              
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              India is the birthplace of some famed astronauts and aerospace scientists. Every citizen of this country is proud of its satellite launches and all other space programs, including our latest achievement of moon landing. Despite all the accomplishments and a budget running in crores of rupees, India still lags behind many developed countries in the space tech industry. India has so far ratified four international treaties relating to outer space with the United Nations, five treaties with other nations/ space agencies and is a signatory to the Moon agreement with the United Nations.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Space Tech has grown rapidly in the last few years at a global level. It is vastly commercialized and is also privatized in few countries. India’s commercial sector for space tech is limited to providing parts, components and units to government run entities like ISRO (Indian Space Research Organisation).
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              ISRO has been providing its satellite launch vehicles to other nations at a very economical price and has so far launched 53 Polar Satellite Launch Vehicles Rockets and 14 Geosynchronous Satellite Launch Vehicle Rockets.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Companies like SpaceX, Blue Origin and Virgin Galactic have blown minds internationally with their work and have shattered the idea that Space Tech should only be handled by Government run organisations. Notions of Space Tourism are in play in today’s era and there is huge intellectual investment in the industry.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Our Space Tech industry is in need of law not only for regulations in consultation with the Department of Space but to also open the gates for private sector to increase the participation in space technology. Our Space Activities Bill was first introduced in November, 2017 and has been pending since. These laws will give a head start to the space tech industry, bring private players into the field and refine our existing policies.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Development of Space Tech Industry will assist space programs in future. It would in country’s best interest to develop a legislature and relevant policies to dig into the potential for Space Tech start-ups. A proper legislature will not just facilitate but also prevent misuse and underutilization of this industry.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <span style={{ fontWeight: 'bold' }}>Disclaimer:</span> <span style={{ fontSize: '1rem' }}>The contents of this publication are solely for information and general knowledge and not for advertising, marketing, soliciting or to build an advocate-client relationship. This is not professional advice or opinion which may be sought before initiation of any legal action. Although everything contained hereinabove is extensively researched, Legal inkwell does not assume any responsibility for any errors, which may inadvertently appear. Legal inkwell will also be not responsible for anyone acting/ refraining by placing reliance on contents of this publication resulting to any kind of loss or damage.</span>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Copyright © 2024
            </Typography>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Spacetech;
