
import React from "react";
import Back from "../common/Back";
import { Typography } from "@material-tailwind/react";
import img from "../images/image/7.1.jpg";
const ServiceLaw = () => {
  return (
    <>
      <section className='text-4xl mb'>
        <Back name='Practice Areas' title='' cover={img} />
      </section>
      <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container">
          <div>
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              Service Law
            </Typography>
            <ul className="list-disc ml-6" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Preparation of Work Regulations / HR Manual / Staff Handbook / Standing Orders;</li>
              <li>Conducting Legal Audit / Due Diligence / Compliance Review of Labour Laws;</li>
              <li>Preparation of Employee Code of Conduct,  Sexual Harassment Policy, etc.;</li>
              <li>Advice on Social Security and Insurance Benefits;</li>
              <li>Severance and Retrenchment Laws;</li>
              <li>Social Benefits;</li>
              <li>Contracts;</li>
              <li>Contract Labour and Regulations;</li>
              <li>Trade Union Laws and related activities;</li>
              <li>Workplace accidents and fatal accident compensation Laws;</li>
              <li>Factories Law;</li>
              <li>Environmental Laws;</li>
              <li>Writs infringing Constitutional Rights.</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};


export default ServiceLaw;