
import React from "react";
import Back from "../common/Back";
import img from "../images/image/7.1.jpg";
import { Typography } from "@material-tailwind/react";
const IntellectualPropertyRights = () => {


  return (
    <>
      <section className='text-4xl mb'>
        <Back name='Practice Areas' title='' cover={img} />
      </section>
      <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container">
          <div>
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              Intellectual Property Rights
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              We offer a full range of legal services for the acquisition, commercialization 
              and enforcement of intellectual property and technology assets in the areas of 
              Patents, Trademarks, Designs and Copyrights. We advise clients on commercial contracts
              relating to the exploitation of Intellectual Property Rights. Our Advocates also have keenly
              handled cases related to Entertainment Laws.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              We provide services in:
            </Typography>
            <ul className="list-disc ml-6" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Copyrights;</li>
              <li>Trademarks;</li>
              <li>Patents;</li>
              <li>Designs;</li>
              <li>Trade Secrets.</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntellectualPropertyRights