import React from "react";
import { Typography } from "@material-tailwind/react";
import Back from "../common/Back";
import img from "../images/image/7.1.jpg";

export function Criminal() {
  return (
    <>
      <section className='text-4xl mb'>
        <Back name='Practice Areas' title='' cover={img} />
      </section>
      <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container">
          <div>
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              Criminal Laws
            </Typography>
            <Typography className="!mt-4 ml-6 mb-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              We offer services relating to following laws: 
            </Typography>
            <ul className="list-disc ml-12" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Fraud;</li>
              <li>Negotiabe Instruments Act, 1881;</li>
              <li>Financial Crimes;</li>
              <li>Prevention of Money Laundering Act, 2002;</li>
              <li>Anti-Money Laundering / Combating the Financing of Terrorism Guidelines by Different authorities;</li>
              <li>Sexual Offences;</li>
              <li>Indian Penal Code, 1860;</li>
              <li>Criminal Procedure Code, 1973;</li>
              <li>New Penal, Criminal and Evidence Laws;</li>
              <li>Bails and Anticipatory Bails;</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Criminal;
