
import React from "react";
import Back from "../common/Back";
import { Typography } from "@material-tailwind/react";
import img from "../images/image/7.1.jpg";
const AlternateDisputeResolution = () => {
  return (
    <>
      <section className='text-4xl mb'>
        <Back name='Practice Areas' title='' cover={img} />
      </section>
      <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container">
          <div>
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              Alternate Dispute Resolution (ADR)
            </Typography>
            <Typography className="!mt-4 ml-4 text-base leading-[27px]" variant="h5" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              International and Domestic Arbitration
            </Typography>
            <Typography className="!mt-4 ml-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              We also have significant experience of conducting arbitration proceedings and 
              are well informed of all the key institutional Rules including those of the LCIA,
              ICC, HKIAC, SIAC and ICSID as well as the UNCITRAL Rules. 
            </Typography>
            <Typography className="!mt-4 ml-4 text-base leading-[27px]" variant="h5" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Mediation & Conciliation
            </Typography>
            <Typography className="!mt-4 ml-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              We actively advice and represent clients in matters undergoing Mediation
              or conciliation proceedings including Lokayukta and other established grievance 
              redressal and out of Court settlements mechanisms. 
            </Typography>
          </div>
        </div>
      </section>
    </>
  );
};

export default AlternateDisputeResolution;