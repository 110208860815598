
import React from "react";
import Back from "../common/Back";
import img from "../images/image/7.1.jpg";
import { Typography } from "@material-tailwind/react";

const ConstitutionalLaw = () => {

 
  return (


    <div>
      <section className="text-4xl mb">
        <Back name="Practice Areas" title="" cover={img} />
        </section>
        <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container">
          <div>
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              Constitutional Law
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Legal Inkwell has a meticulous practive of Constitutional Law. The Constitution of India
              is the largest and the most detailed in the whole world. It is the base guideline for all of 
              our laws. We provide services in:
            </Typography>
            <ul className="list-disc ml-6 mt-2" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Civil and Criminal Writs</li>
              </ul>
              <ul className="list-disc ml-16" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Habeas Corpus;</li>
              <li>Quo Warranto;</li>
              <li>Certiorari;</li>
              <li>Prohibition;</li>
              <li>Mandamus.</li>
              </ul>
            <ul className="list-disc ml-6 mt-2" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Public Interest Litigation;</li>
            </ul>
            
          </div>
        </div>
      </section>
    </div>
  )
}
 
export default ConstitutionalLaw