import React from "react"
import { footer } from "../../data/Data"
import "./footer.css"
import { Typography } from "@material-tailwind/react";

const Footer = () => {

  const SITEMAP = [
    {
      title: "Company",
      links: [
        { name: "Our Team", url: "/CoreTeam" },
        { name: "Firm Profile", url: "/practice-areas" }
      ]
    },
    {
      title: "Help Center",
      links: [
        { name: "Instagram", url: "https://www.instagram.com/legal_inkwell?igsh=OHA3Z3l3cmViYTFy", target: "_blank" }, // Added target="_blank"
        { name: "LinkedIn", url: "https://www.linkedin.com/company/legal-inkwell/", target: "_blank" }, // Added target="_blank"
        { name: "Contact Us", url: "/contact" }
      ]
    },
    {
      title: "Resources",
      links: [
        { name: "Articles", url: "/articles" },
        { name: "Newsletter", url: "/Newsltr" }
      ]
    }
  ];


  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="relative w-full  bg-[#B28975] text-white">
        <div className="mx-auto w-full max-w-7xl px-8 ">
        <div className="mx-auto grid w-full grid-cols-1 gap-8 py-4 md:grid-cols-2 lg:grid-cols-3">
      {SITEMAP.map(({ title, links }, key) => (
        <div key={key} className="w-full">
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-4 font-bold uppercase text-md text-white"
          >
            {title}
          </Typography>
          <ul className="space-y-1">
            {links.map(({ name, url, target }, key) => ( // Destructuring target from link object
              <Typography key={key} as="li" color="blue-gray" className=" text-white">
                <a
                  href={url}
                  target={target} // Using target from the link object
                  className="inline-block py-0 pr-2 transition-transform hover:scale-105 text-white"
                >
                  {name}
                </a>
              </Typography>
            ))}
          </ul>
        </div>
      ))}
    </div>
          <div className="flex w-full flex-col items-center justify-center border-t border-blue-gray-50 text-blue-gray-100 py-2 md:flex-row md:justify-between">
            <Typography
              variant="small"
              className="mb-4 text-center  text-white md:mb-0"
            >
              &copy; {currentYear} <a href="https://Legalinkwell.com/">Legal Inkwell</a>. All
              Rights Reserved.
            </Typography>
            <div className="flex gap-4  text-white sm:justify-center">

              <Typography as="a" href="https://www.linkedin.com/company/legal-inkwell/" target="blank" className="opacity-80 transition-opacity hover:opacity-100">
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 32 32">
                  <path d="M 7.5 5 C 6.132813 5 5 6.132813 5 7.5 L 5 24.5 C 5 25.867188 6.132813 27 7.5 27 L 24.5 27 C 25.867188 27 27 25.867188 27 24.5 L 27 7.5 C 27 6.132813 25.867188 5 24.5 5 Z M 7.5 7 L 24.5 7 C 24.785156 7 25 7.214844 25 7.5 L 25 24.5 C 25 24.785156 24.785156 25 24.5 25 L 7.5 25 C 7.214844 25 7 24.785156 7 24.5 L 7 7.5 C 7 7.214844 7.214844 7 7.5 7 Z M 10.4375 8.71875 C 9.488281 8.71875 8.71875 9.488281 8.71875 10.4375 C 8.71875 11.386719 9.488281 12.15625 10.4375 12.15625 C 11.386719 12.15625 12.15625 11.386719 12.15625 10.4375 C 12.15625 9.488281 11.386719 8.71875 10.4375 8.71875 Z M 19.46875 13.28125 C 18.035156 13.28125 17.082031 14.066406 16.6875 14.8125 L 16.625 14.8125 L 16.625 13.5 L 13.8125 13.5 L 13.8125 23 L 16.75 23 L 16.75 18.3125 C 16.75 17.074219 16.996094 15.875 18.53125 15.875 C 20.042969 15.875 20.0625 17.273438 20.0625 18.375 L 20.0625 23 L 23 23 L 23 17.78125 C 23 15.226563 22.457031 13.28125 19.46875 13.28125 Z M 9 13.5 L 9 23 L 11.96875 23 L 11.96875 13.5 Z"></path>
                </svg>
              </Typography>

              <Typography as="a" href="https://www.instagram.com/legal_inkwell?igsh=OHA3Z3l3cmViYTFy" target="blank" className="opacity-80 transition-opacity hover:opacity-100">
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path
                    fill-rule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </Typography>

            </div>
          </div>
        </div>
      </footer>

    </>
  )
}

export default Footer
