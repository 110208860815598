
import React from "react";
import Back from "../common/Back";
import { Typography } from "@material-tailwind/react";
import img from "../images/image/7.1.jpg";
const BankingFinance = () => {

  return (


    <div>
      <section className="text-4xl mb">
        <Back name="Practice Areas" title="" cover={img} />
        </section>
        <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container">
          <div>
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              Banking & Finance
            </Typography>
            <Typography className="mb-4" variant="h5" style={{ fontFamily: 'TheSeasons' }}>
              Banking
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Our Firm has an excellent track record of legal matters pertaining to Banking Laws.
              We have frequent appearances before Debt Recovery Tribunal, Jaipur. We have experience
              of dealing with debt recovery cases of secured creditors as well as debtors.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              We provide regular service and advice on all types of commercial, financial
              and other banking transactions including negotiations, loan agreements and 
              other documentations to corporations in India and abroad. We provide services in:
            </Typography>
            <ul className="list-disc ml-6" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Debt Recovery - SARFAESI Act & RDB Act;</li>
              <li>Reserve Bank of India's Regulations and Compliances;</li>
            </ul>
            <Typography className="mt-4 mb-4" variant="h5" style={{ fontFamily: 'TheSeasons' }}>
              Finance
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              We provide services in:
            </Typography>
            <ul className="list-disc ml-6" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Insolvency and Restructuring</li>
              </ul>  
            <Typography className="!mt-4 text-base ml-8 leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Restructuring of debts and securities and insolvency has become a new field
              in itself with the onset of Insolvency Bankruptcy Code, 2016. Our Firm has handled 
              deeply tangled cases of bankruptcy and insolvency. We are in liason with various 
              Insolvency Resolution Professionals and Company Secretaries who lend us a helping hand
              from time to time to offer a strategic solution to our Clientele which includes 
              Creditors, Debtors and Stakeholders during the Insolvency proceedings before Hon'ble Courts / Tribunals.
            </Typography>
            <ul className="list-disc ml-6" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Securities Law</li>
              </ul>
              <ul className="list-disc ml-14" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>SEBI: Securities and Exchange Board of India;</li>
              <li>SCRA: Securities Contracts (Regulations) Act, 1956;</li>
              <li>LODR: Listing Agreement.</li>
              </ul>
              <ul className="list-disc ml-6" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Foreign Exchange</li>
              </ul>
              <ul className="list-disc ml-14" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>FEMA: Foreign Exchange Management Act;</li>
              <li>FERA: Foreign Exchange Regulation Act;</li>
              <li>Reserve Bank of India's Regulations and Compliances;</li>
              <li>ODI: Overseas Direct Investment;</li>
              <li>FDI: Foreign Direct Investment.</li>
              </ul>
          </div>
        </div>
      </section>
    </div>
  )
}

export default BankingFinance