import React, { useEffect } from "react";
import img from "../../images/image/7.1.jpg";
import Back from "../../common/Back";
import img1 from "../White Collar Crimes/Picture1.png";
import { Typography } from "@material-tailwind/react";

const Whitecollar = () => {
  useEffect(() => {
    window.scrollTo(0, 380);
  }, []);

  return (
    <div>
      <section className='text-4xl mb'>
        <Back name='Articles' title='' cover={img} />
      </section>
      <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container items-center">
          <img
            src={img1}
            alt="pink blazer"
            className="h-[362px] w-[733px] object-cover mb-8 mt-4"
            style={{ objectPosition: 'center', objectFit: 'cover', marginLeft: 'auto', marginRight: 'auto' }}
          />
          <div className="md:pl-8 flex flex-col justify-center">
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              White Collar Crimes
            </Typography>
            <Typography variant="h5" className="text-gray-600">
              
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Crimes are the acts which are against the whole society at large but most of the criminal activities affect the people who are financially poor or incapable to protect themselves, now question here arises is the rich class more protected than the poor class. Answer to this question can be very tangled because crimes related to money can also happen to the section of the society which is financially sound. We analyse how white collar crimes came into existence or were they already existing but all these years, thinkers confused them with fraud. We will discuss kinds of white collar crimes, some of the biggest scams in the world and status of these crimes in different countries. Financial sector is very important for any country and protecting it from blunders has become a huge task especially after development of technology. Banks being the backbone of financial sector are the main targets for white collar criminals. Position of banks especially in India has also been discussed hereforth.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            The task to protect economies of countries from these frauds and crimes has become one of the important missions of governments around the world. Many rules, regulations, laws, provisions et cetera are enacted to keep general public safe. This crime does not just affect the person who has been cheated but it also affects the whole economy of a country when committed at large scale. We will shine light on the fact that white collar crimes are here to stay so a common man should be educated about it and the governments should have strong laws to protect the country.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Since decades criminology has always been revolving around crimes among the “lower class”. An act affecting the whole society is termed as crime but in case of white collar crimes in financial industry, it affects mostly the part consisting of business and professional people. Edwin Sutherland had first coined the term “white collar crimes”. He used it first time in his speech in American Sociologist Society in Philadelphia on December 27, 1939.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            White collar crimes can be traced back to the time when they were known as “organized crimes”. Organized crimes have been around since the Prohibition in 1900s. Familiar names such as Al Capone rose in fame as Bootleg Liquor birthed the first crimes committed in pursuit of financial gain. In India, organized crimes were first studied by Mr. V.K. Sarraf, Commissioner of Police (Retired), 1995. His case study focused on activities criminal gangs and their inter-gang warfare in Mumbai City. The Wall Street Crisis that began in 2008 witnessed lot of white collar crimes although there had been claims that there were far too few prosecutions. Earlier than this savings and loan scandals of the early 1980s and Enron scandal of the early 2000s also saw happening and trial of white collar crimes. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            White collar crime are crimes which are here to stay because with the growing technology, its far more easier to get into someone’s monetary matters. The growing technology has been a big disadvantage here because it comes with its own pros and cons. Growing cases of fraud using the cyber assistance is a circumstance which we all are aware of whether it is a credit card fraud or just a fake phone call, we have experienced it on some or other level. There is need for strict provisions and security related to cyber exposure in case of financial matters in all the states.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            The term white collar crime is not particularly defined in law but the acts mostly revolve around fraud of different kinds. The Black law’s dictionary defines fraud as, “A knowing misinterpretation of the truth or concealment of a material fact to induce another to act to his or her detriment.” During investigation of various frauds FBI also defined fraud, its definition is small, simple and limited, it says fraud is, “lying, cheating and stealing.”  
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            White Collar offences are the crimes mostly connected to dishonest means and intentions. It connects to a fraudulent action of a person and intentional lie which misleads another person. Misappropriations of funds, money laundering, forgery, Insider trading, are some of the basic examples of White Collar crimes. White Collar crimes now a days are a common offence in almost all the financial sector. Different sector have different types and ways to defraud the person. White Collar crimes are considered as a part of criminal law and before executing a person all the provisions given in a statute must be compiled. These kinds of financial crimes are non violative crimes which are done against a person or a corporation with an intention to defraud and which results in financial loss of the person or corporation. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Some of the White Collar Offences include the following types:- 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <ol style={{ listStyle: 'none', marginLeft: '20px', paddingLeft: '20px' }}>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Theft Offences:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    These are the offences that include theft of money or any other valuable product does not include any kind of force or violent act. For example: Extortion may sometimes include threat to a person but on the other hand Blackmailing generally includes threatening a person in lieu of some information.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Fraud offences:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    These are the kinds of offences which include a proper scheme to obtain a valuable information from a person through fraudulent act or misrepresentation. For example: making of forged document, tax fraud, etc.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Insider Trading:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    It means buying or selling of the company’s stock and other securities by the person who is a part of the management of the company. In some countries Insider Trading is considered to be legal while in other countries it is considered to be illegal as it seems to be unfair to other investors who do not have access to that information.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Money Counterfeiting:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    It means production of the currency which is not legally sanctioned by the government and are in violation of the state’s legal policy. Counterfeit basically means a replica of something. Some people use counterfeited bills, some use counterfeited products such as watches, jewelries, bags, etc. while some uses counterfeited electronic products. All of these are one and the other way to financial crime only.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Bankruptcy Fraud:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    Frauds which are committed by individuals or businesses against bank such as by concealing the mortgaged asset, or misleading the creditors, or by showing false bankruptcy, etc.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Identity Fraud:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    Illegal use of one’s personal information such as for purchasing credit card, mobile phones, sim cards, or for committing other serious offences.
                  </span>
                </li>
              </ol>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Apart from this, Public Corruption by public officials is also an another way of financial crime. Public officials misuse their power and authority for their personal gains by demanding money. Bribery is the most common example of this by which some businessman gives money or other valuable property to the authority in lieu of the favorable treatment or to get rid of the crime. These all are the examples which are covered under the proceeds of crime act apart from this there are many other offences also which are dealt by other legislative statutes. The requirements given in the statute must be compiled before giving any decision. Some of these offences are:- 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <ol style={{ listStyle: 'none', marginLeft: '20px', paddingLeft: '20px' }}>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Conspiracy to defraud:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    Conspiracy is when two or more persons jointly plan to defraud the company or the management of any company. In a conspiracy no single member can be held liable individually. Both or all of the members have same liability against the crime.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Fraud Act:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    This act introduces an offence of fraud with a maximum imprisonment of up to ten years. It specifies the particular three types of ways in which a fraud can be committed they are: false representation, wrongfully misrepresenting an information, and abuse of dominant position.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Fraudulent Trading:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    It is an offence that is mostly done in business by defrauding the credits while trading and it is dealt with the help of Companies Act. Sometimes sole traders also participate in a fraudulent business that is also considered to be an offence.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Fake Accounting:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    It is dealt under the Theft Act. Fake accounting includes the false applications, valuations, making of fake bills, etc. destroying of false information is also considered as an offence in the Theft Act.
                  </span>
                </li>
              </ol>
            </Typography>

            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            <strong>U. S. System</strong>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            The United States of America has witnessed huge blunders in financial industry and all of them were connected to white collar crimes. The prosecution and trial of white collar crime is very different in USA because a normal trial is over in two to three days but in trial of white collar crime it takes weeks because the number of exhibits alone can be overwhelming with addition to documents, emails et cetera which are to reviewed by the judge and jury members. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            One of the disadvantages in the US laws regarding white collar crimes is that they are not deterrent. Key to enforcement of any law is deterrence. The Wall Street crisis of 2008 has failed to produce any prosecutorial response. The United States has prosecuted 36.8 percent fewer white collar crimes than it did in 1995. The crimes being discussed under white collar crimes are not victimless; they can destroy economy as well as can also affect a life time savings of a family. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            There are organizations and legal provisions which are enacted to curb these crimes. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <ol style={{ listStyle: 'none', marginLeft: '20px', paddingLeft: '20px' }}>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>FBI:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    This is an investigating agency. It investigates from slick online scams to corporate stocks and health frauds.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Fraud Enforcement and Recovery Act, 2009:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    It is a public law of the United States enforced in 2009. It is enacted to enforce law for federal fraud laws mainly related to financial institutions, mortgage fraud, securities fraud and commodities fraud.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Health Care Fraud Enforcement Act, 2009:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    This act is enacted to enhance government tools and provide remedy for medical frauds.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>National Check Fraud Centre:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    It is a source of assistance. It provides information and alert reports for counterfeiting check, forgery, check frauds, bank frauds and white collar crimes.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>United States Sentencing Commission- White Collar Crimes:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    It is an independent agency in the judicial branch of government. It establishes policies and practices and also issue guidelines. It advises and assists congress and the executive branch. Collecting, analyzing, researching information on federal crimes and the issuing sentences is also one of its prime purposes.
                  </span>
                </li>
              </ol>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Scams in the country are not a new concept. It has been evolving since history. The JEEP SCANDAL CASE, 1948  was the first ever corruption case that happened in independent India. The then Indian high commissioner to Britain V.K.Krishna Menon signed a contract with foreign firms for the purchase of 200 army jeeps worth Rs. 80lakhs ignoring all the protocols. After that many cases have held till date. The SECURITIES SCAM of 1992 is considered to be the mother of all scams. Harshad Mehta was the main accused of the scam who were brought against 27 criminal charges but were only convicted for 4. He manipulated the stock market, the investments of several investors. He was also alleged for bribing the Prime Minister. The financial scandal value was Rs. 49.99 billion for which Harshad Mehta was accused by Bombay High Court and Supreme Court of India which took place in Bombay Stock Exchange (BSE). 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Banks are the centric point of the financial industry. It is very rare where a person is not associated with a bank. From a small farmer to multi billionaire firms, everybody utilizes facilities provided by banks. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            In India, banks ‘history in modern era started with the nationalization of banks in 1969 and with that we have been witnessing drastic changes in the banking industry. The economy was liberalized and the global competition increased and the consequence was entrance of new private and foreign players in the banking industry. The best parameter to study the change in the banking industry is the use of information technology. Information technology has changed the whole aspect of the banking sector, now it is not a just an institution which accepts deposits and lends money, its roles have been diversified. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Indian banking industry has brought itself at par with the global standards by making huge changes and accepting new methods and techniques to run the industry. Growing technology also grows challenges and one of the biggest challenges to the modern banking industry is threat to security. Banks are the basic foundation of financial industry for a reason and that is they have information which is always under danger of leaking. Curbing these threats will just not need adequate measures but also an efficient system to implement them so that the industry can run smoothly. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            <strong>Recent Developments</strong>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Nationalization in 1969 brought many changes. Indian banks were brought at par with the international standards. It has diversifies roles, provides innovation and customer value added services are given. Some of the important recent developments are as follows: 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <ol style={{ listStyle: 'none', marginLeft: '20px', paddingLeft: '20px' }}>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Focus on customer service:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    Retaining existing customers and attracting new customers is the goal of present banks. The standards are maintained by providing quality customer services.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Consolidation and geographic expansion:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    Banks are expanding by branching their network. They also use outsourcing agencies for this and some are also going for virtual banking system. This is all done so that customers can easily access them.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Globalization of banking service:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    Liberalization of the economy has exposed the banks to the global market. It has made the environment highly competitive.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Reducing the cost and growth of revenue:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    Information technology reduces cost and fees-based and value-added services grow revenue. This is the system on which today’s banks work.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Know your customer:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    There are Know Your Customer guidelines under which a customer identification procedure is followed. It is done to avoid money laundering and misuse by antisocial entities.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Corporate governance:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    Corporate governance is about making the system transparent, which will make banks and their employees accountable, resulting in corporate fairness. It specifies rights, responsibilities, rules, and procedures.
                  </span>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>Use of technology:</strong>
                  <span style={{ display: 'block', marginLeft: '20px', paddingLeft: '20px' }}>
                    It is the biggest change in the banking industry. Use of technology has given it a new face. E-Banking is a huge step towards the development of this industry.
                  </span>
                </li>
              </ol>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            <strong>Information Technology Initiatives</strong>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            As discussed earlier, we can conclude that if we compare the history banking industry has gone through a lot of changes. It has changed completely and is more concerned about its consumers now. Information technology has given the banking industry a new facet. Technology is growing with speed of light and with that industries, lifestyle, governments and other aspects of human life are also changing. This change is also seen in the banking industry as ATMs, Credit cards, Internet banking. Information technology has not left the banking sector untouched. All the banks are competing in this modern era to be customer friendly and they achieve that by using technology. We can see this usage of technology in many banks but State Bank of India and Punjab National Bank are the best examples in this area . Some of the major initiatives are as under: 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <ol style={{ listStyle: 'none', marginLeft: '20px', paddingLeft: '20px' }}>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>1) MICR based cheque system</strong>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>2) Electronic Clearing Service (ECS), Electronic Funds Transfer (EFT) and Special Electronic Funds Transfer (SEFT)</strong>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>3) Inter bank funds transfer by Real Time Gross Settlement (RTGS)</strong>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>4) Online Tax Accounting System (OLTAS) for transmission of tax-based information from banks to Tax Information Network (TIN)</strong>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>5) Anytime Money (ATMs)</strong>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>6) E-Banking</strong>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>7) Regional branch offices and Total Branch Automation for smooth networking to reach large base of customers across the country</strong>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>8) Automated Clearing House (ACH)</strong>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>9) Digital Data Storage</strong>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <strong>10) Single Window System at banks for all the transactions</strong>
                </li>
              </ol>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            <strong>Initiatives by the Reserve Bank of India</strong>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Reserve Bank of India is the governing body of the banking sector. It protects the consumers as well as the banks from any frauds. It has taken many initiatives to keep check on fraud and thereby preventing them. It has acquainted itself with police personnel and enacted various rules and regulations for defending general public from fraud. Various sections of IT act are also covered by RBI to be in flow with the growing technology. RBI has issued some guidelines in order to implement these initiatives, some of them are:
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <ol style={{ listStyle: 'none', marginLeft: '20px', paddingLeft: '20px' }}>
                <li style={{ marginBottom: '1rem' }}>
                  1) Appointment system administrator in each bank
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  2) Policies for E-Security to be pre-approved by Board of Directors
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  3) Legal access control techniques introduced like user IDs, passwords, smart cards et cetera
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  4) Preventing unauthorized access to systems by using firewall
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  5) E-mail servers and application servers isolated.
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  6) Messages and other computer accesses received and sent are monitored on a regular basis
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  7) Periodic penetration tests are undertaken like breaking of passwords on a regular basis
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  8) Maintenance of proper strategic data backup
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  9) Applications of banks facilitating legal purposes should be kept recorded properly
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  10) Security infrastructure should be tested before the implementation
                </li>
              </ol>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            Banking sector has revolutionized after the liberalization and privatization of the economy. It has opened up to new opportunities as well as challenges. It has become easy and smooth with little bit of vulnerability. Information technology entering this sector has given this industry a helping hand to survive in this competitive global economy. E-Banking has its pros and cons but with proper complementation of cyber laws this technology can be blessing for this industry.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            <strong>Conclusion</strong>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
            White collar crime are crimes which are here to stay because with the growing technology, its far more easy to get into someone’s monetary matters. The growing technology has been a big disadvantage here because it comes with its own pros and cons. These types of offences are the crimes mostly connected to dishonest means and intentions. It connects to a fraudulent action of a person and intentional lie which misleads another person. The task to protect economies of countries from these frauds and crimes has become one of the important missions of governments around the world. Many rules, regulations, laws, provisions et cetera are enacted to keep general public safe. Banks have made their own new rules and regulations. They are coming up with their policies so that an individual can feel safe while doing transactions. E-Banking has become popular among this generation. Information technology has given the banking industry a new facet. Technology is growing with speed of light and with that industries, lifestyle, governments and other aspects of human life are also changing. This change is also seen in the banking industry as ATMs, Credit cards, Internet banking. Information technology has not left the banking sector untouched.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <span style={{ fontWeight: 'bold' }}>Disclaimer:</span> <span style={{ fontSize: '1rem' }}>The contents of this publication are solely for information and general knowledge and not for advertising, marketing, soliciting or to build an advocate-client relationship. This is not professional advice or opinion which may be sought before initiation of any legal action. Although everything contained hereinabove is extensively researched, Legal inkwell does not assume any responsibility for any errors, which may inadvertently appear. Legal inkwell will also be not responsible for anyone acting/ refraining by placing reliance on contents of this publication resulting to any kind of loss or damage.</span>
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Copyright © 2024
            </Typography>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Whitecollar;
