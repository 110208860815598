import React from "react";
import img from "../images/image/7.1.jpg";
import Back from "../common/Back";


const Contact = () => {
  return (
    <>
      <section className=' text-4xl mb '>
        <Back name='Contact Us' title='' cover={img} />
        <div className='container text-2xl  '>
          <div className='shadow px-5 py-5 mx-5 my-10 rounded-2xl'>
          <div className="text-container mx-8 font-bold mb-5 mt-8">
              <p>Email ID:</p>
            </div>
            <div>
              <div className="text-container mb-10 mx-16 underline">
              <a href="mailto:legalinkwell.advocates@gmail.com">legalinkwell.advocates@gmail.com</a>
              </div>
            </div>
            <div className="text-container mx-8 font-bold mb-5 mt-8">
              <p>Phone Number:</p>
            </div>
            <div className="text-container mb-10 mx-16">
              <p>+91-9694001001</p>
            </div>
            <div className="text-container mx-8 font-bold mb-5">
              <p>Addresses:</p>
            </div>
            <div className="text-container mb-10 mx-16">
              <p>Jaipur Office:</p>
              <p>10, Nehru Path, Friends Colony, Krishna Nagar 2, Lal Kothi, Jaipur - 302015</p>
                <a href="https://www.google.com/maps/search/?api=1&query=26.8881278454708,75.79958171739085" target="_blank" rel="noopener noreferrer">
                  <span role="img" aria-label="location pin">📍</span>
                </a>
            </div>
            <div className="text-container mb-10 mx-16">
              <p>Udaipur Branch:</p>
              <p>24, Ganpati Nagar, Bohra Ganesh Ji Main Road, Udaipur - 313001</p>
            </div>
            <div className="text-container mb-10 mx-16">
              <p>Bhilwara Branch:</p>
              <p>A-637, Bapu Nagar, Bhilwara - 311001</p>
            </div>
            {/* <button>Submit Request</button> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
