import React from "react";
import img from "../images/image/7.1.jpg";
import Back from "../common/Back";
import "../home/featured/Featured.css";
import { Typography } from "@material-tailwind/react";

const DataPrivacy = () => {
  return (
    <>
      <section className='text-4xl mb'>
        <Back name='Practice Areas' title='' cover={img} />
      </section>
      <section className="py-16 px-8" style={{ fontFamily: 'TheSeasons' }}>
        <div className="mx-auto container">
          <div>
            <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
              Data Privacy Laws
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              We offer services like drafting and execution of Non-Disclosure Agreements
              to combat the issue of Data Privacy for private Individuals and 
              Corporates while adhering to the laws, rules and guidelines. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              The emergence of data protection laws arise from the right to 
              privacy which is a fundamental right guaranteed under Articles 
              14, 19 and 21 of the Constitution of India. 
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              Data Protection aims to protect all types of sensitive data
              at both individual and corporate levels. While for corporates,
              sensitive data constitutes of financial data, internal Management
              documents and policies, marketing and business strategies, client
              information, various associations, IPRs, etc. whereas for individuals
              sensitive data would comprise of personal data like biometric data, 
              information related to identity, health information, financial data, 
              IPRs, etc. which requires protection from any misuse or mishandling and 
              ensuring right to privacy.
            </Typography>
            <Typography className="!mt-4 text-base leading-[27px]" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              With the digitalisation of almost all the areas of work, data privacy
              has become an imminent need and so as to secure the interest of public
              and protect the data, various laws have been framed and enforced in India
              which includes: 
            </Typography>
            <ul className="list-disc ml-6" style={{ fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
              <li>Information Technology Act, 2000;</li>
              <li>Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011;</li>
              <li>Digital Personal Data Protection, 2023;</li>
              <li>General Data Protection Regulation (GDPR) Compliance.</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataPrivacy;
